import {Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class GlobalBagService {
    public toastMessagesBox;
    private params = new Array();
    public config;
    public lang;
    private sK = 'knooppunter';

    constructor(public sanitizer: DomSanitizer) {
        this.toastMessagesBox = (document.querySelector('#messages') as HTMLElement);
        this.hydrateConfig();
        this.hydrateLanguage();
    }

    public getConfig(key) {

        const domain = this.getParam('domain');
        const value = key[domain];
        return value;
    }

    private hydrateLanguage() {
        this.lang = {
            title: {
                en: 'CityOnTrail navigation',
                fr: 'Navigation Lepointnoeud',
                nl: 'CityOnTrail navigatie',
                de: ''
            },
            menuButNavig: {
                en: 'Navigate',
                fr: 'Naviguer',
                nl: 'Navigeren',
                de: ''

            },
            menuRouteInfo: {
                en: 'Road info',
                fr: 'Information',
                nl: 'Route informatie',
                de: ''
            },
            menuEnd: {
                en: 'End trip',
                fr: 'Fin du voyage',
                nl: 'Route beëindigen',
                de: ''
            },
            mapFollow: {
                en: 'Follow',
                fr: 'Suivre',
                nl: 'Volg',
                de: ''
            }
        };
    }

    public t(item) {
        const language = this.getParam('language');
        return item[language];
    }


    public checkS() {
        // console.log(this.sha1(this.getParam('routeId') + '-' + this.getParam('uid'))) ;
        const vtc = this.getParam('routeId') + '-' + this.getParam('uid');
        const sg = this.getParam('s');
        const shaStr = this.SHA1(vtc + this.sK);
        if (shaStr === sg) {
            return true;
        } else {
            return false;
        }
    }

    private hydrateConfig() {
        // domainDrupalDetailUrl
        this.config = {
            defaultLanguage: {
                'pwa.knooppunter.com': 'nl',
                'pwa.lepointnoeud.com': 'fr'
            },
            domainDrupalDetailUrl: {
                'app.cityontrail.com': 'www.cityontrail.com',
                'pwa.lepointnoeud.com': 'www.lepointnoeud.com'
            },
            favIconPath: {
                'app.cityontrail.com': '/assets/favicon.png',
                'pwa.lepointnoeud.com': '/assets/faviconnoeud.png'
            },
            homeUrl: {
                'app.cityontrail.com': 'https://www.cityontrail.com/',
                
            }
        };


    }

    public setParam(name, value) {

        this.params[name] = value;
    }

    public getParam(name) {
        return this.params[name];
    }


    async presentToast(message, options, unik = '') {
        if (unik !== '') {
            const testExist = document.querySelector('.' + unik);
            // console.log('.' + unik);
            // console.log(testExist);
            if (testExist !== null) {
                return;
            }
        }

        const messageEl = document.createElement('div');
        messageEl.classList.add('message');
        if (unik !== '') {
            messageEl.classList.add(unik);
        }
        if (options.duration === undefined) {
            options.duration = 3000;
        }
        if (options.messageType !== undefined) {
            messageEl.classList.add(options.messageType);
        } else {
            messageEl.classList.add('info');
        }
        messageEl.innerHTML = message;
        this.toastMessagesBox.appendChild(messageEl);

        setTimeout(() => {
            messageEl.remove();
        }, options.duration);


        // const toast = await this.toastCtrl.create(myOptions);
        // return toast.present();
    }

    public setDomain(domain = null) {
        if (domain === null || domain === '') {
            domain = document.location.hostname;
            if (domain === 'localhost') {
                domain = 'pwa.cityontrail.com';
            }

        }
        // const cssDomainStr = domain.replaceAll('.', '');
        const cssDomainStr = domain.split('.').join('_');
        console.log('domain is ' + domain);

        this.setParam('domain', domain);
        this.setParam('cssDomain', cssDomainStr);
    }

    public getUrlDomain() {
        return this.getParam('domain') + '/';
    }

    public setScheme(scheme = null) {
        if (scheme === null) {
            scheme = document.location.protocol;
        }
        this.setParam('scheme', scheme);
    }

    public getUrlScheme() {
        return this.getParam('scheme') + '://';
    }

    public getCurrentRouteUrlDetail() {
        const drupalId = this.getParam('drupalId');
        let url = '';
        if (drupalId === null) {
            url = this.getParam('communityUrlDetail');
        } else {
            // url = 'https://' + this.getUrlDomain() + 'node/' + drupalId;
            url = 'https://' + this.getConfig(this.config.domainDrupalDetailUrl) + '/node/' + drupalId + '?fromApp=1';
            console.log('iframe detail : ');
            console.log(url);
        }
        return url;
    }

    public getHomeLink() {

        return this.getConfig(this.config.homeUrl);
    }

    public SHA1(msg) {
        function rotate_left(n, s) {
            var t4 = (n << s) | (n >>> (32 - s));
            return t4;
        };

        /*function lsb_hex(val) {
            var str = "";
            var i;
            var vh;
            var vl;

            for (i = 0; i <= 6; i += 2) {
                vh = (val >>> (i * 4 + 4)) & 0x0f;
                vl = (val >>> (i * 4)) & 0x0f;
                str += vh.toString(16) + vl.toString(16);
            }
           return str;
        };*/
        function cvt_hex(val) {
            var str = '';
            var i;
            var v;
            for (i = 7; i >= 0; i--) {
                v = (val >>> (i * 4)) & 0x0f;
                str += v.toString(16);
            }
            return str;
        };

        function Utf8Encode(string) {
            string = string.replace(/\r\n/g, '\n');
            var utftext = '';
            for (var n = 0; n < string.length; n++) {
                var c = string.charCodeAt(n);
                if (c < 128) {
                    utftext += String.fromCharCode(c);
                } else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                } else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
            }
            return utftext;
        };

        var blockstart;
        var i, j;
        var W = new Array(80);
        var H0 = 0x67452301;
        var H1 = 0xEFCDAB89;
        var H2 = 0x98BADCFE;
        var H3 = 0x10325476;
        var H4 = 0xC3D2E1F0;
        var A, B, C, D, E;
        var temp;
        msg = Utf8Encode(msg);
        var msg_len = msg.length;
        var word_array = new Array();
        for (i = 0; i < msg_len - 3; i += 4) {
            j = msg.charCodeAt(i) << 24 | msg.charCodeAt(i + 1) << 16 |
                msg.charCodeAt(i + 2) << 8 | msg.charCodeAt(i + 3);
            word_array.push(j);
        }
        switch (msg_len % 4) {
            case 0:
                i = 0x080000000;
                break;
            case 1:
                i = msg.charCodeAt(msg_len - 1) << 24 | 0x0800000;
                break;
            case 2:
                i = msg.charCodeAt(msg_len - 2) << 24 | msg.charCodeAt(msg_len - 1) << 16 | 0x08000;
                break;
            case 3:
                i = msg.charCodeAt(msg_len - 3) << 24 | msg.charCodeAt(msg_len - 2) << 16 | msg.charCodeAt(msg_len - 1) << 8 | 0x80;
                break;
        }
        word_array.push(i);
        while ((word_array.length % 16) != 14) {
            word_array.push(0);
        }
        word_array.push(msg_len >>> 29);
        word_array.push((msg_len << 3) & 0x0ffffffff);
        for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
            for (i = 0; i < 16; i++) {
                W[i] = word_array[blockstart + i];
            }
            for (i = 16; i <= 79; i++) {
                W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
            }
            A = H0;
            B = H1;
            C = H2;
            D = H3;
            E = H4;
            for (i = 0; i <= 19; i++) {
                temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            for (i = 20; i <= 39; i++) {
                temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            for (i = 40; i <= 59; i++) {
                temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }

            for (i = 60; i <= 79; i++) {
                temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }

            H0 = (H0 + A) & 0x0ffffffff;
            H1 = (H1 + B) & 0x0ffffffff;
            H2 = (H2 + C) & 0x0ffffffff;
            H3 = (H3 + D) & 0x0ffffffff;
            H4 = (H4 + E) & 0x0ffffffff;

        }
        var tmp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
        return tmp.toLowerCase();
    }

}
