import {Injectable} from '@angular/core';
import { GlobalBagService } from './global-bag.service';

@Injectable({
    providedIn: 'root'
})
export class CotApiService {
    private body = {
        action: '',
        id: '',
        api_key: 'API1',
        auth_token: 'nokey',
        version: '11',
        type: 'public',
        route_id: '',
        user_id: '0',
        sender: 'pwa',
        language:'',
    };

    private urlApi = 'https://www.cityontrail.com/api/';
    private routeDetailsAction = 'gettrail'+'?XDEBUG_SESSION=PHPSTORM';
    private logAction = 'log';
    private language = 'en';

    constructor(public globalParams: GlobalBagService) {
        
        this.urlApi='https://www.cityontrail.com/api/';
        if (this.globalParams.getUrlDomain() == 'app.dev.cityontrail.com') {
            this.urlApi='https://www.dev.cityontrail.com/api/';
        }

    }

    public async getRouteDetails(routeId: any) {
        

        this.language = this.globalParams.getParam('language');


        let options = {body: '', method: 'post'};
        let body = this.body;
        body.id = routeId;
        body.language = this.language;

        options.body = JSON.stringify(this.body);


        const webResp = await fetch(this.urlApi + this.routeDetailsAction, options);
        const data = await webResp.json();
        console.log (data);
        return data;

    }

    public async sendKppAction(kppId: any, routeId: any, userId: any) {
        const options = {body: '', method: 'post'};
        let body = this.body;
        body.route_id = routeId;
        body.action = 'app.route.kpp';
        body.id = kppId;
        body.user_id = userId;
        options.body = JSON.stringify(this.body);



        const webResp = await fetch(this.urlApi + this.logAction, options);
        const data = await webResp.json();

        return data;
    }

    public async sendStartAction( routeId: any, userId: any) {
        const options = {body: '', method: 'post'};
        let body = this.body;
        body.route_id = routeId;
        body.action = 'app.route.start';
        body.id = routeId;
        if (userId !== null && userId !== undefined){
            body.user_id = userId;
        }
        options.body = JSON.stringify(this.body);

        const webResp = await fetch(this.urlApi + this.logAction, options);
        const data = await webResp.json();

        return data;
    }

} 
